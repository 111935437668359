// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/offices';

export default {
  async getOffice(officeId) {
    const response = await requestService.get(`${prefix}/${officeId}`);
    return response?.data?.data;
  },
  async getOffices(params) {
    const response = await requestService.get(`${prefix}`,
        {...params}
        );
    return response?.data;
  },
  async addOffice(payload) {
    const response = await requestService.post(`${prefix}`, payload);
    return response?.data?.data;
  },
  async updateOffice(officeId, payload) {
    const response = await requestService.post(`${prefix}/${officeId}`, payload);
    return response?.data?.data;
  },
  async deleteOffice(officeId, payload) {
    const response = await requestService.delete(`${prefix}/${officeId}`, payload);
    return response?.data?.data;
  },
  async selectCurrentOffice(officeId) {
    const response = await requestService.patch(`${prefix}/${officeId}/select?_method=patch`, {}, {}, false);
    return response?.data?.data;
  },
  async officeChangeOrder(officeId, payload) {
    const response = await requestService.post(`${prefix}/${officeId}/move`, payload, {}, false);
    return response?.data?.data;
  },
};
