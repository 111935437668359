<template>
  <v-dialog
      v-model="visibility"
      max-width="800"
      content-class="cross__dialog"
      :persistent="changed"
      :transition="$dialogTransition"
      @click:outside="showConfirmDialog"
      scrollable
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        <v-row v-if="!$vuetify.breakpoint.smAndDown" no-gutters justify="space-between">
        <span class="font-20" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
            $t('form.edit_geo')
          }}</span>
        </v-row>
      </v-card-title>
      <v-card-text class="py-5">
        <div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputGeo.name"
                  :label="$t('form.country')"
                  :maxlength="25"
                  hide-details="auto"
                  @keypress="validationForNickName"
                  @paste="validationForNickName"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputGeo.country_code"
                  :label="$t('form.country_code')"
                  :maxlength="25"
                  hide-details="auto"
                  @keypress="validationForCountryCode"
                  @paste="validationForCountryCode"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn
                dark
                block
                class="br-5 text-transform-none"
                @click="showConfirmDialog"
            >{{ $t("btn.cancel") }}
            </v-btn
            >
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onUpdate()"
            >{{ $t("btn.update") }}
            </v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
        :visible="visibleConfirm"
        @close="visibleConfirm = false"
        @delete="onClose"
    />
  </v-dialog>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {
  validationForNickName, validationForCountryCode
} from "@/mixins/helpers";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import _ from "lodash";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import geoService from "@/services/request/geo/geoService";

export default {
  name: "GeoEditDialog",

  data: () => ({
    loading: false,
    changed: false,
    visibleConfirm: false,
    inputGeo: {},
    copyGeo: {},
  }),
  mixins: [dialogMixin, validationMixin, notifications],

  validations: {
    inputGeo: {
      name: {required},
      country_code: {required},
    },
  },

  components: {
    DeleteConfirmDialog,
    ConfirmLeftDialog,
  },

  props: {
    geo: {
      required: false,
      default: () => ({}),
    },
  },

  async mounted() {
    await this.onMounted();
  },

  methods: {
    validationForCountryCode,
    validationForNickName,
    async onMounted() {
      this.loading = true;
      this.inputGeo = _.cloneDeep(this.geo);
      this.copyGeo = _.cloneDeep(this.inputGeo);
      this.loading = false;
    },
    onClose() {
      this.$v.$reset();
      this.$emit("close");
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onClose();
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("_method", 'patch');
          formData.append("name", this.inputGeo.name);
          formData.append("country_code", this.inputGeo.country_code);

          const user = await geoService.updateGeo(this.inputGeo.id, formData);
          this.copyGeo = {...this.inputGeo};
          this.inputGeo = {...this.copyGeo};

          this.$emit("updateUser", user);
          this.setSuccessNotification(this.$t("profile.successfully_updated"));
          this.onClose();
        } catch (e) {
          this.setErrorNotification(e.error.description);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  watch: {
    inputGeo: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyGeo, this.inputGeo);
      },
    },
  },
};
</script>